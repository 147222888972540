/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.btn-default {
    color: #333;
    border-color: #ccc;
}

.btn-pass {
    color: #fff;
    background-color: #5cb85c;
    border-color: #4cae4c;
}

.btn-fail {
    color: #fff;
    background-color: #d9534f;
    border-color: #d43f3a;
}

.btn-na {
    color: #fff;
    background-color: #5bc0de;
    border-color: #46b8da;
}

.btn-default:hover {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
}

.toggle-button {
    display: inline-block;
    white-space: nowrap;
    margin: 5px 0;
}

.question-row-answer-invalid .toggle-button {
    border: dashed 2px #dc3545;
    border-radius: 5px;
}

.question-row-fail-reason-invalid .ctl-fail {
    border: dashed 2px #dc3545;
}

.question-alert {
    font-weight: bold;
    display: inline-block;
    background: #dc3545;
    color: #fff;
    padding: 0 8px;
    margin-right: 7px;
}

.toggle-button .btn {
    border-radius: 0;
    border-right-width: 0;
}

    .toggle-button .btn:first-child {
        border-radius: 0.25rem 0 0 0.25rem;
    }

    .toggle-button .btn:last-child {
        border-radius: 0 0.25rem 0.25rem 0;
        border-right-width: 1px;
    }

.checkList {
    padding: 0;
    margin: 0;
    list-style-type: none;
    margin-bottom: 30px;
}

.question-row {
    padding: 10px;
    border-bottom: solid 1px #ccc;
}

.section-optional .question-row {
    padding-top: 0;
}

.section-optional-text {
    padding: 10px;
    padding-bottom: 0;
}

.question-row-pass, .question-row-fail, .question-row-na {
    border-radius: 10px;
}

    .question-row-pass .btn, .question-row-fail .btn, .question-row-na .btn {
        border-color: #aaa;
    }

.question-row-pass {
    background-color: #e4f6e4;
}

.question-row-fail {
    background-color: #f6e6e5;
}

.question-row-na {
    background-color: #e4f9ff;
}

.checkList li {
    display: block;
}

.ctl-fail {
    margin: 5px 0;
}

.question-cell {
    margin-top: 10px;
}

.checklist-submit-row {
    margin: 12px 0;
}

.login-submit-error,
.checklist-submit-error {
    color: #dc3545;
    display: inline-block;
    margin-left: 10px;
}

.checklist-section-header {
    margin: 20px 0 10px 0;
}

.navbar .btn-link {
    border: none;
}

.btn-with-help {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.btn-with-help .btn {
    flex-shrink: 0;
    margin-right: 0.5rem;
}

.modal-show-always {
    display: block;
    position: relative;
}

.vca-popup-content, .vca-popup-content-content {
    position: relative !important;
    background: transparent !important;
    padding: 0 !important;
    width: auto !important;
    border: none !important;
}

.signature-canvas-container {
    width: 100%;
}

.signature-canvas {
    border: solid 1px #ccc;
    width: 100%;
    height: 226px;
    display: block;
    margin-bottom: 10px;
}

.signature-canvas.error {
    border: dashed 3px red;
}

.insurance-details {
    white-space: pre-wrap;
}

.ios-share-image {
    width: 20px;
    height: 20px;
}